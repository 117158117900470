'use strict';

/*jslint bitwise: true */

angular.module('ngLocale', [], ['$provide', function($provide) {
var PLURAL_CATEGORY = {ZERO: 'zero', ONE: 'one', TWO: 'two', FEW: 'few', MANY: 'many', OTHER: 'other'};
function getDecimals(n) {
  n = n + '';
  var i = n.indexOf('.');
  return (i == -1) ? 0 : n.length - i - 1;
}

function getVF(n, optPrecision) {
  var v = optPrecision;

  if (undefined === v) {
    v = Math.min(getDecimals(n), 3);
  }

  var base = Math.pow(10, v);
  var f = ((n * base) | 0) % base;
  return {v: v, f: f};
}

$provide.value('$locale', {
  'DATETIME_FORMATS': {
    'AMPMS': [
      'AM',
      'PM'
    ],
    'DAY': [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ],
    'ERANAMES': [
      'Before Christ',
      'Anno Domini'
    ],
    'ERAS': [
      'BC',
      'AD'
    ],
    'FIRSTDAYOFWEEK': 6,
    'MONTH': [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    'SHORTDAY': [
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat'
    ],
    'SHORTMONTH': [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ],
    'WEEKENDRANGE': [
      5,
      6
    ],
    'fullDate': 'EEEE, d. MMMM y',
    'longDate': 'd. MMMM y',
    'medium': 'dd.MM.y HH:mm:ss',
    'mediumDate': 'dd.MM.y',
    'mediumTime': 'HH:mm:ss',
    'short': 'dd.MM.yy HH:mm',
    'shortDate': 'dd.MM.yy',
    'shortTime': 'HH:mm'
  },
  'NUMBER_FORMATS': {
    'CURRENCY_SYM': '\u20ac',
    'DECIMAL_SEP': '.',
    'GROUP_SEP': ',',
    'PATTERNS': [
      {
        'gSize': 3,
        'lgSize': 3,
        'maxFrac': 3,
        'minFrac': 0,
        'minInt': 1,
        'negPre': '-',
        'negSuf': '',
        'posPre': '',
        'posSuf': ''
      },
      {
        'gSize': 3,
        'lgSize': 3,
        'maxFrac': 2,
        'minFrac': 2,
        'minInt': 1,
        'negPre': '-\u00a4\u00a0',
        'negSuf': '',
        'posPre': '\u00a4\u00a0',
        'posSuf': ''
      }
    ]
  },
  'id': 'de-at',
  'pluralCat': function(n, optPrecision) {  
      var i = n | 0;  
      var vf = getVF(n, optPrecision);  
      if (i == 1 && vf.v === 0) {    
        return PLURAL_CATEGORY.ONE;  
      }  
      return PLURAL_CATEGORY.OTHER;
    }
});
}]);
